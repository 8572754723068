





















































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { GetJindu } from "@/request/mark";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    Empty,
  },
})
export default class Name extends Vue {
  @Prop()
  private id: any;

  @Watch("id", { immediate: true })
  private idChange() {
    this.getData();
  }
  private data: any = [];
  private getData() {
    const params: any = {
      params: {
        task_id: this.id,
      },
    };
    GetJindu(this, params).then((res: any) => {
      this.data = res;
    });
  }
}
